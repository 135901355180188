<template>
  <div id="app">
    <div>
    <nav-bar />
    <aside-menu :menu="menu" />
    <router-view />
    </div>
    <footer-bar />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'
import { mapState } from 'vuex'

export default {
  name: 'HomePage',
  components: {
    FooterBar,
    AsideMenu,
    NavBar
  },
  data() {
    return {
    }
  },
  computed: {
    menu () {
        const menu = ['General',
        [
            {
                to: '/',
                icon: 'desktop-mac',
                label: 'Home'
            }
        ]]
        menu.push('Batteries',
        [
            {
                to: '/MyBattery/mybatteries',
                label: 'My Batteries',
                icon: 'file-document-multiple-outline'
            }
        ])
        return menu
    },
    ...mapState(['api'])
  }
}
</script>
