<template>
  <footer v-show="isFooterBarVisible" class="footer">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="footer-copyright">
              <b>&copy; {{ year }} TNO</b> &mdash; Digital Battery Passport demo UI
            </div>
          </div>
          <div class="level-item">
            <div class="logo">
              <figure class="image">
                <img src="https://www.tno.nl/publish/pages/5604/tno-logo-1484x835_003_.jpg" />
              </figure>
            </div>
          </div>
        </div>
      </div>
  </footer>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'

export default {
  name: 'FooterBar',
  computed: {
    year () {
      return dayjs().year()
    },
    ...mapState(['isFooterBarVisible'])
  }
}
</script>
