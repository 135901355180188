import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [{
        meta: {
            title: 'Dashboard',
            requiresAuth: false
        },
        path: '/',
        name: 'home',
        component: Home,
        props: true
    },
    {
        meta: {
            title: 'My Batteries',
            requiresAuth: false
        },
        path: '/MyBattery/mybatteries',
        name: 'MyBatteries',
        component: () =>
            import ('../views/batteries/MyBatteries.vue'),
        props: true
    }
]

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

function tokenValid(token) {
    const tokenData = JSON.parse(Buffer.from(token.split('.')[1], 'base64'))
    return tokenData.exp > (new Date().getTime() / 1000) - 120
}

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('token') == null) {
            next({
                name: 'login',
                params: { nextUrl: to.fullPath }
            })
        } else {
            if (!tokenValid(localStorage.getItem('token'))) {
                store.commit('logout')
                next({
                    name: 'login',
                    params: { nextUrl: to.fullPath }
                })
            } else {
                next()
            }
        }
    } else {
        next()
    }
})


export default router