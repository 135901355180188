/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router & Store */
import router from './router'
import store from './store'

/* Vue. Main component */
import App from './App.vue'


import { createPinia, PiniaVuePlugin } from 'pinia'

/* Default title tag */
const defaultDocumentTitle = 'Digigtal Battery Passport WEBUI'

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
    store.commit('asideMobileStateToggle', false)

    if (to.meta && to.meta.title) {
        document.title = `${to.meta.title} — ${defaultDocumentTitle}`
    } else {
        document.title = defaultDocumentTitle
    }
})

Vue.config.productionTip = false

Vue.use(Buefy)
Vue.use(PiniaVuePlugin)
const pinia = createPinia()


new Vue({
    router,
    store,
    pinia,
    render: h => h(App)
}).$mount('#app')