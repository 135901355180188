<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Digital Battery Passport Demo WEBUI
    </hero-bar>
    <section class="section is-main-section">
    <card-component>
      <em>
        <p class="pb-2"> Some text to place
        </p>
        <p class="pb-2">The architecture of the use case can be seen in the following diagram. (Create one SVG) </p>
      </em>
    </card-component>
    <b-image src="Architecture.png" alt="architecture" style="width:50%;height:50%;"></b-image>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'
import { mapState } from 'vuex'
export default {
  name: 'HomePage',
  components: {
    CardComponent,
    HeroBar,
    TitleBar
  },
  data () {
    return {
    }
  },
  computed: {
    titleStack () {
      return ['']
    },
    ...mapState(['api'])
  },
  methods: {
  }
}
</script>
